import { graphql, navigate } from 'gatsby';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Display Components
import ManageGroupCardList from 'display-components/membership/manage-group-card-list';
import { MembershipSettingsErrorModal } from 'display-components/membership/modals';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { CartReviewSectionHeader } from 'components/cart-review-section-header';
import HealthConditions from 'components/cart/health-conditions/health-conditions.component';
import CheckoutSectionHeader from 'components/checkout/checkout-section-header';
import PaymentInformation from 'components/checkout/payment-information';
import CheckoutLayout from 'components/layouts/checkout';
import ManageMembershipPaymentMethodBody from 'components/manage-membership/manage-membership-payment-method-body';
import ConsiderationsAndFinalPrices from 'components/membership-request-visit-considerations-and-final-prices/membership-request-visit-considerations-and-final-prices.component';

// State
import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountProfileMembershipSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from 'state/medical-conditions/medical-conditions.routines';
import {
    isLoadingAllergiesSelector,
    isLoadingHealthConditionsSelector
} from 'state/medical-conditions/medical-conditions.selector';
import {
    membershipPreAuthorizeTelemedicineRoutine,
    membershipRequestVisitMemberIdRoutine
} from 'state/membership/membership.routines';
import {
    isMembershipAvailableSelector,
    membershipDetailSelector,
    membershipIsLoadingDetailsSelector,
    membershipIsOnDemandSelector,
    membershipIsTelemedicineAvailableSelector,
    membershipRequestVisitMemberIdSelector,
    membershipRequiresPaymentSelector
} from 'state/membership/membership.selector';
import { paymentsActions } from 'state/payments/payments.reducers';
import { paymentsSelectedMethodSelector } from 'state/payments/payments.selectors';

// Types & interfaces
import { ManageGroupCardProps, MembershipMembers, MembershipPreAuthorizeTelemedicineResponse } from 'types/membership';

// Utils
import { isAxiosError } from 'util/axiosClient';
import { convertToTitleCase } from 'util/string';

// Hooks
import { useHealthConditions } from 'hooks/useHealthConditions';
import { useOpenHours } from 'hooks/useOpenHours';

// Styles
import './request-visit-page.style.scss';

export const formatPhoneNumber = (phoneNumber: string): string => {
    try {
        if (phoneNumber !== undefined) {
            const numericOnly = phoneNumber.replace(/\D/g, '');
            const match = numericOnly.match(/^(\d{3})(\d{3})(\d{4})$/);
            return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
        }
        return phoneNumber;
    } catch {
        return phoneNumber;
    }
};

const RequestVisitPage = ({ data }: { data: GatsbyTypes.RequestVisitPageDataQuery }) => {
    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isOpen } = useOpenHours();
    const { existingAllergies, existingConditions, userHasNotSubmittedAllergies, userHasNotSubmittedConditions } =
        useHealthConditions(true);

    // Local Constants
    const { backgroundImage } = data;
    const initialTitle = t('pages.requestTelemedicineVisit.healthProfile.title');

    // Local State
    const [selectedMemberID, setSelectedMemberID] = useState<string | null>();
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false);
    const [membershipMembersList, setMembershipMembersList] = useState<MembershipMembers[]>([]);
    const [healthProfileData, setHealthProfileData] = useState({
        initialTitle,
        patientEpostPatientNum: '',
        memberPayloadData: {} as MembershipMembers,
        planName: ''
    });
    const [isReviewed, setIsReviewed] = useState<boolean | undefined>(undefined);
    const [error, setError] = useState<string>('');
    const [isBusy, setIsBusy] = useState<boolean>(false);

    // Selectors
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const { membershipID } = useSelector(accountProfileMembershipSelector);
    const membershipObject = useSelector(membershipDetailSelector);
    const loadingDetails = useSelector(membershipIsLoadingDetailsSelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);
    const requestVisitMemberIdSelected = useSelector(membershipRequestVisitMemberIdSelector);
    const hasVisitAvailable = useSelector(membershipIsTelemedicineAvailableSelector);
    const profileObject = useSelector(accountProfileSelector);
    const selectedCardForVisit = useSelector(paymentsSelectedMethodSelector);
    const isMembershipPaymentRequired = useSelector(membershipRequiresPaymentSelector);
    const isMembershipAvailable = useSelector(isMembershipAvailableSelector);
    const isLoadingMembershipHealthConditions = useSelector(isLoadingHealthConditionsSelector);
    const isLoadingAllergies = useSelector(isLoadingAllergiesSelector);

    const activeCreditCards = useMemo(() => {
        return allPaymentData?.filter((card) => card.cardActive);
    }, [allPaymentData]);

    const defaultCreditCard = useMemo(() => {
        return activeCreditCards?.find((card) => card.defaultCard);
    }, [activeCreditCards]);

    const shouldShowPaymentSection = useMemo(() => {
        const noVisitsAvailable = !isOnDemandPlan && !hasVisitAvailable;
        const noPaymentMethod = !selectedCardForVisit;

        return isOnDemandPlan || noVisitsAvailable || noPaymentMethod;
    }, [isOnDemandPlan, hasVisitAvailable, selectedCardForVisit]);

    const isActiveCreditCard = ({ cardSeqNum }: { cardSeqNum: string }): boolean => {
        return Boolean(activeCreditCards?.some((active) => cardSeqNum === active.cardSeqNum));
    };

    useEffect(() => {
        if (!isMembershipAvailable) {
            navigate('/secure/medicine-cabinet');
        }
    }, [isMembershipAvailable]);

    useEffect(() => {
        if (isMembershipPaymentRequired) {
            navigate('/secure/membership');
        }
    }, [isMembershipPaymentRequired]);

    useEffect(() => {
        if (isReviewed === true) {
            setError('');
        }
    }, [isReviewed]);

    useEffect(() => {
        if (activeCreditCards && activeCreditCards.length > 0 && defaultCreditCard) {
            setIsReviewed(true);
            setError('');
        } else {
            setIsReviewed(false);
        }
    }, [activeCreditCards, defaultCreditCard]);

    useEffect(() => {
        if (profileObject) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [dispatch, profileObject]);

    useEffect(() => {
        // assign the default card as the selected payment method when:
        // 1) It's the only payment method available
        // 2) The selected card is no longer an active credit card
        if (
            (defaultCreditCard !== undefined && !selectedCardForVisit) ||
            (selectedCardForVisit && !isActiveCreditCard(selectedCardForVisit))
        ) {
            dispatch(paymentsActions.setSelectedPaymentMethod(defaultCreditCard));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultCreditCard]);

    useEffect(() => {
        return () => {
            // remove the selected memberId when the component is unmounted
            dispatch(membershipRequestVisitMemberIdRoutine.trigger(null));
            dispatch(paymentsActions.setSelectedPaymentMethod());
        };
    }, [dispatch]);

    useEffect(() => {
        if (membershipObject?.members) {
            const filteredMembers =
                membershipObject.members
                    .filter((member) => ['active', 'accepted'].includes(member.status.toLowerCase()))
                    .filter((member) => {
                        // it will skip filtering if the member is the same as the profile
                        if (membershipObject?.epostPatientNum === profileObject?.epostPatientNum) {
                            return true;
                        }

                        // if the member is not the same as the profile, it will filter it
                        if (member.epostPatientNum !== profileObject?.epostPatientNum) {
                            return false;
                        }
                        return true;
                    }) || [];

            setMembershipMembersList(filteredMembers);

            if (filteredMembers.length === 1) {
                dispatch(membershipRequestVisitMemberIdRoutine.trigger(membershipID));
            }

            if (filteredMembers.length < 2) {
                setSelectedMemberID(membershipID);
                dispatch(membershipRequestVisitMemberIdRoutine.trigger(membershipID));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, membershipID, membershipObject]);

    useEffect(() => {
        if (membershipMembersList.length === 1) {
            dispatch(
                medicalConditionsDetailsRoutine.trigger({
                    epostPatientNumFamily: profileObject?.epostPatientNum
                })
            );
            dispatch(
                medicalConditionsAllergiesDetailsRoutine.trigger({
                    epostPatientNumFamily: profileObject?.epostPatientNum
                })
            );
            setHealthProfileData({
                initialTitle,
                patientEpostPatientNum: profileObject?.epostPatientNum as string,
                memberPayloadData: membershipMembersList[0],
                planName: membershipObject?.planName as string
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, membershipMembersList, profileObject]);

    const handleSelectMember = useCallback(
        (memberID: string) => {
            setSelectedMemberID(memberID);
            setIsErrorMessageVisible(false);

            // If the user already selected an user, it should be able to change the user only by selecting another one.
            if (requestVisitMemberIdSelected) {
                dispatch(membershipRequestVisitMemberIdRoutine.trigger(memberID));
            }
        },
        [dispatch, requestVisitMemberIdSelected]
    );

    const handleSubmitSelectedMember = useCallback(() => {
        if (!selectedMemberID) {
            setIsErrorMessageVisible(true);
            return;
        }
        dispatch(membershipRequestVisitMemberIdRoutine.trigger(selectedMemberID));
    }, [dispatch, selectedMemberID]);

    const fetchMedicalConditions = (ePostPatientNum: string) => {
        if (ePostPatientNum && ePostPatientNum.length > 0) {
            dispatch(
                medicalConditionsDetailsRoutine.trigger({
                    epostPatientNumFamily: ePostPatientNum
                })
            );
            dispatch(
                medicalConditionsAllergiesDetailsRoutine.trigger({
                    epostPatientNumFamily: ePostPatientNum
                })
            );
        }
    };

    const findMemberOnList = (epostPatientNum: string) => {
        const [member] = membershipMembersList.filter((member) => member.epostPatientNum === epostPatientNum);
        return member;
    };

    const inputRadioElement = (memberID: string, ePostPatientNum: string, memberName?: string, index?: number) => (
        <div className="manage-group-card__content__radio">
            <input
                type="radio"
                name={`option-member`}
                value={memberID}
                id={`option-member-${memberID}`}
                className="manage-group-card__content__input"
                onChange={() => handleSelectMember(ePostPatientNum)}
                onClick={() => {
                    fetchMedicalConditions(ePostPatientNum);
                    setHealthProfileData({
                        initialTitle: memberName && index !== 0 ? `${initialTitle} of ${memberName}` : initialTitle,
                        patientEpostPatientNum: ePostPatientNum,
                        memberPayloadData: findMemberOnList(ePostPatientNum),
                        planName: membershipObject?.planName as string
                    });
                }}
            />
        </div>
    );

    // Members to be shown in the card
    const membersPayload: ManageGroupCardProps[] = membershipMembersList.map((member, index) => {
        const memberName = `${convertToTitleCase(member.firstName)} ${convertToTitleCase(member.lastName)}`;
        return {
            memberName,
            accountType: index === 0 ? (t('pages.requestTelemedicineVisit.currentUser') as 'You') : member.accountType,
            email: member.email,
            phoneNumber: formatPhoneNumber(member.phoneNumber),
            showRadioButton: membershipMembersList.length > 1,
            inputRadioElement:
                membershipMembersList.length > 1
                    ? inputRadioElement(String(member.membershipId), member.epostPatientNum, memberName, index)
                    : undefined,
            isMinorDisclaimerVisible: selectedMemberID === String(member.epostPatientNum)
        };
    });

    const onChangePaymentMethod = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'modal-membership-payment',
                size: 'lg',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('pages.profile.membership.manageMembership.modals.changePaymentTitle')}
                        subTitle={t('pages.profile.membership.manageMembership.modals.changePaymentSubtitle')}
                        body={
                            <ManageMembershipPaymentMethodBody
                                isModal
                                currentFlow="request-visit"
                                selectedPayment={selectedCardForVisit}
                            />
                        }
                    />
                )
            })
        );
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const showGenericErrorModal = useCallback(
        (
            memberName: string,
            variant:
                | 'sendInvitation'
                | 'addMember'
                | 'sendInvitationAssociated'
                | 'generateScheduleLink'
                | 'paymentError'
        ) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => {
                        dispatch(closeModal({}));
                        // Delaying to enable the CTA to prevent unneeded calls
                        setTimeout(() => {
                            setIsBusy(false);
                        }, 800);
                    },
                    headerContent: (
                        <BirdiModalHeaderDanger headerText={t('components.membershipModals.errorTitle')} icon="alert" />
                    ),
                    bodyContent: (
                        <MembershipSettingsErrorModal
                            variation={variant}
                            memberName={convertToTitleCase(memberName)}
                            t={t}
                            onClose={() => {
                                dispatch(closeModal({}));
                                // Delaying to enable the CTA to prevent unneeded calls
                                setTimeout(() => {
                                    setIsBusy(false);
                                }, 500);
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const showOutsideHoursModal = (): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            dispatch(
                openModal({
                    showClose: false,
                    isModalCentered: true,
                    type: 'primary',
                    size: 'lg',
                    backdrop: 'static',
                    headerContent: <BirdiModalHeaderDanger icon="alert" headerText={t('modals.outOfHours.title')} />,
                    bodyContent: <BirdiModalContentAlt subTitle={t('modals.outOfHours.description')} />,
                    ctas: [
                        {
                            label: t('modals.outOfHours.cancel'),
                            variant: 'text-blue',
                            onClick: () => {
                                dispatch(closeModal({}));
                                reject();
                            },
                            dataGALocation: 'OutOfHoursRequestAVisit'
                        },
                        {
                            label: t('modals.outOfHours.continue'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                                resolve();
                            },
                            dataGALocation: 'OutOfHoursRequestAVisit'
                        }
                    ]
                })
            );
        });
    };

    const handleOnSubmit = async (epostPatientNumPayer: string, epostPatientNum: string, cardSeqNum: string) => {
        setIsBusy(true);

        if (!isOpen) {
            try {
                await showOutsideHoursModal();
            } catch {
                setIsBusy(false);
                return;
            }
        }

        if (!isReviewed) {
            setError(t('pages.profile.membership.manageMembership.errors.paymentMethod'));
            setIsBusy(false);
            return;
        } else {
            setError('');
        }
        if (userHasNotSubmittedAllergies || userHasNotSubmittedConditions) return;

        dispatch(
            membershipPreAuthorizeTelemedicineRoutine.trigger({
                payload: {
                    epostPatientNumPayer,
                    epostPatientNum,
                    cardSeqNum
                },
                onSuccess: (response: MembershipPreAuthorizeTelemedicineResponse) => {
                    navigate(response.patientLoginLinkSteadyMD.link);
                },
                onFailure: (errors: unknown) => {
                    // DRX-3319: Since users might encounter various related errors (such as connection errors),
                    // This validation ensures that if the API response fails, the appropriate modal is displayed.
                    if (isAxiosError(errors)) {
                        showGenericErrorModal('', 'generateScheduleLink');
                    } else {
                        showGenericErrorModal('', 'paymentError');
                    }
                }
            })
        );
    };

    if (!isMembershipAvailable) {
        return null;
    }

    return (
        <CheckoutLayout
            backgroundImage={backgroundImage}
            nodeTitle={t('pages.requestTelemedicineVisit.title')}
            title={t('pages.requestTelemedicineVisit.title')}
            eyebrowText={t('pages.requestTelemedicineVisit.eyebrow')}
            className="request-visit-layout"
        >
            <div className="request-visit">
                <ToastBox variant="info">
                    <div className="request-visit-toast-box">
                        <div>
                            <CircleInfo />
                        </div>
                        <p>{t('pages.requestTelemedicineVisit.toast')}</p>
                    </div>
                </ToastBox>

                {membersPayload.length > 1 && (
                    <>
                        <CartReviewSectionHeader label={t('pages.requestTelemedicineVisit.patientInformation.title')} />
                        <div>
                            <Row>
                                <Col>
                                    {!isErrorMessageVisible && membersPayload && membersPayload.length > 1 && (
                                        <p className="manage-group-card-list__container-list__select-patient text-left">
                                            {t('pages.requestTelemedicineVisit.patientInformation.selectPatientInfo')}
                                        </p>
                                    )}
                                    {isErrorMessageVisible && (
                                        <p className="manage-group-card-list__container-list__select-patient-error text-left">
                                            {t('pages.requestTelemedicineVisit.patientInformation.errorMessage')}
                                        </p>
                                    )}

                                    {loadingDetails ? (
                                        <SpinnerInline />
                                    ) : (
                                        <ManageGroupCardList
                                            isSingleAccount={membersPayload.length === 1}
                                            payload={membersPayload}
                                            hideContactDetails
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </>
                )}

                {!requestVisitMemberIdSelected && (
                    <div className="request-visit-total-ctas partial">
                        <Button
                            type="button"
                            variant="text"
                            className="text-cerulean btn-demi"
                            label={t('pages.requestTelemedicineVisit.ctas.back')}
                            onClick={() => {
                                navigate('/secure/membership');
                            }}
                            dataGAFormName="OrderPrescription"
                            dataGAFormStepName="Checkout"
                        />
                        <Button
                            type="button"
                            className="sm-full btn-bold"
                            label={t('pages.requestTelemedicineVisit.ctas.continue')}
                            onClick={handleSubmitSelectedMember}
                        />
                    </div>
                )}

                {requestVisitMemberIdSelected && (
                    <>
                        <CartReviewSectionHeader label={healthProfileData.initialTitle} />
                        <HealthConditions
                            isUserHasNotSubmittedConditions={userHasNotSubmittedConditions}
                            existingConditions={existingConditions}
                            existingAllergies={existingAllergies}
                            isUserHasNotSubmittedAllergies={userHasNotSubmittedAllergies}
                            isMembershipHealthConditions={true}
                            isLoadingHealthConditions={isLoadingMembershipHealthConditions}
                            isLoadingAllergies={isLoadingAllergies}
                        />

                        {/* DRX-2286: Payment Methods */}
                        {shouldShowPaymentSection && (
                            <div className="request-visit-payment-information">
                                <CheckoutSectionHeader
                                    label={t('pages.requestTelemedicineVisit.paymentInformation.title')}
                                />

                                <PaymentInformation
                                    shouldHidePaymentType
                                    showPaymentMethodDisclaimer
                                    onChange={onChangePaymentMethod}
                                    selectedPaymentMethod={selectedCardForVisit}
                                    creditCardsData={activeCreditCards}
                                    error={error}
                                    dataGAFormStepName="RequestAVisit"
                                />
                            </div>
                        )}

                        {/* DRX-2245: Telemedicine visit total */}
                        <div>
                            <Row>
                                <Col>
                                    {selectedCardForVisit && (
                                        <ConsiderationsAndFinalPrices
                                            member={healthProfileData.memberPayloadData}
                                            planOwner={findMemberOnList(membershipObject?.epostPatientNum || '')}
                                            isOnDemandPlan={isOnDemandPlan}
                                            hasVisitAvailable={hasVisitAvailable}
                                            telehealthPrice={membershipObject?.telehealthPrice}
                                            planName={healthProfileData.planName}
                                            selectedCardForVisit={selectedCardForVisit}
                                            defaultCreditCard={defaultCreditCard}
                                        />
                                    )}
                                    <div className="request-visit-total">
                                        <div className="request-visit-total-ctas">
                                            <Button
                                                type="button"
                                                variant="text"
                                                className="text-cerulean btn-demi"
                                                label={t('pages.requestTelemedicineVisit.ctas.back')}
                                                onClick={() => {
                                                    navigate('/secure/membership');
                                                }}
                                                dataGAFormName="OrderPrescription"
                                                dataGAFormStepName="Checkout"
                                                isBusy={isBusy}
                                                disabled={isBusy}
                                            />
                                            <Button
                                                async
                                                type="button"
                                                variant="primary"
                                                className="sm-full btn-bold"
                                                label={t('pages.requestTelemedicineVisit.ctas.continue')}
                                                disabled={
                                                    userHasNotSubmittedAllergies ||
                                                    userHasNotSubmittedConditions ||
                                                    isBusy
                                                }
                                                onClick={() => {
                                                    handleOnSubmit(
                                                        profileObject?.epostPatientNum as string,
                                                        healthProfileData.patientEpostPatientNum,
                                                        selectedCardForVisit?.cardSeqNum as string
                                                    );
                                                }}
                                                isBusy={isBusy}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </CheckoutLayout>
    );
};

export default RequestVisitPage;

export const query = graphql`
    query RequestVisitPageData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
